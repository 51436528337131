import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import { merge } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class FuseConfirmationService
{
    private _matDialog: MatDialog = inject(MatDialog);

    /**
     * Constructor
     */
    constructor(private translocoService: TranslocoService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
    {
        const _defaultConfig: FuseConfirmationConfig = {
            title      : this.translocoService.translate("Confirmer l'action"),
            message    : this.translocoService.translate('Êtes vous sûr de vouloir supprimer cet élément ?'),
            icon       : {
                show : true,
                name : 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            },
            actions    : {
                confirm: {
                    show : true,
                    label: this.translocoService.translate('Confirmer'),
                    color: 'warn',
                },
                cancel : {
                    show : true,
                    label:  this.translocoService.translate('Annuler'),
                },
            },
            dismissible: false,
        };

        // Merge the user config with the default config
        const userConfig = merge({}, _defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus   : false,
            disableClose: !userConfig.dismissible,
            data        : userConfig,
            panelClass  : 'fuse-confirmation-dialog-panel',
        });
    }
}
