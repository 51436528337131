import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  private snackbarSubject = new Subject<{message: string, action?: string}>();
  snackbar$ = this.snackbarSubject.asObservable();

  open(message = 'Opération effectuée avec succès', action: string = null) {
    this.snackbarSubject.next({message, action});
  }
}
