import { environment } from 'environments/environment.development';

import { Injectable, inject } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import {
    AccountsService,
    User,
    UsersService,
    SimpleBlocTemplate,
    SimpleBlocTemplatesService,
    SimplePage,
    SimplePagesService,
    VacanciesService,
    Vacancy,
    ArticlesService,
    ContactsService,
    Contact,
    CandidateVacanciesService,
    CandidateVacancy,
    Article,
    ArticleCategoriesService,
    ArticleCategory
} from '../api/core';


@Injectable({
    providedIn: 'root'
})
export class CoreService {
    http = inject(HttpClient);
    readonly utils = inject(UtilsService);

    readonly account = new AccountsService(this.http, environment.apiUrl, null);
    readonly users = this.utils.extendClass<User, UsersService>(UsersService, environment.apiUrl, 'Users');

    readonly blocTemplates = this.utils.extendClass<SimpleBlocTemplate, SimpleBlocTemplatesService>(SimpleBlocTemplatesService, environment.apiUrl, 'SimpleBlocTemplates');
    readonly pages = this.utils.extendClass<SimplePage, SimplePagesService>(SimplePagesService, environment.apiUrl, 'SimplePages')
    readonly vacancies = this.utils.extendClass<Vacancy, VacanciesService>(VacanciesService, environment.apiUrl, 'Vacancies')
    readonly articles = this.utils.extendClass<Article, ArticlesService>(ArticlesService, environment.apiUrl, 'Articles')
    readonly contacts = this.utils.extendClass<Contact, ContactsService>(ContactsService, environment.apiUrl, 'Contacts');

    readonly articleCategories = this.utils.extendClass<ArticleCategory, ArticleCategoriesService>(ArticleCategoriesService, environment.apiUrl, 'ArticleCategories');

    readonly candidates = this.utils.extendClass<User, UsersService>(UsersService, environment.apiUrl, 'Users');
    readonly candidateVacancies = this.utils.extendClass<CandidateVacancy, CandidateVacanciesService>(CandidateVacanciesService, environment.apiUrl, 'Vacancies');
}
