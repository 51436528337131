const CORE_API_PROD = 'https://sportcarriere-api.digitransform.co';
// const CORE_API_PROD = 'http://51.77.137.150:5000';

export const isBrowser = typeof window !== 'undefined';

export const environment = {
    production: true,
    apiUrl: CORE_API_PROD,
    url: CORE_API_PROD,
    urlFront: 'https://meent.cloud-digitransform.co',
};

export function displayImage(urlImage: string, url = environment.url) {
    const IMAGE = 'assets/images/detail/yugi-back.jpg';

    if (!urlImage) {
        return IMAGE;
    }

    if (urlImage.startsWith('[') && urlImage.endsWith(']')) {
        try {
            const img: string = (JSON.parse(urlImage) as string[])[0];
            return `${url}/${img}`;
        } catch (error) {
            return IMAGE;
        }
    }

    if (urlImage?.startsWith('http')) {
        return urlImage;
    }
    if (!urlImage.includes('/')) {
        return IMAGE;
    }

    if (urlImage.startsWith('data:')) {
        return urlImage;
    }

    return `${url}/${urlImage}`;
}
