import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { CoreService } from "./core.service";
import { environment } from "environments/environment.development";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { LocalService } from "../user/local.service";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AssetsDbService } from './assets-db.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Title, Meta } from '@angular/platform-browser';
import { SnackService } from '@fuse/services/snack/snack.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { AccountsService } from "../api/core/api/accounts.service";
import { UtilsService } from "./utils.service";
import { FileUploadService } from './file.upload.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class UowService {
    readonly utils = inject(UtilsService)
    readonly session = inject(LocalService);

    readonly core = inject(CoreService);
    readonly assetsDb = inject(AssetsDbService);

    readonly files = inject(FileUploadService);

    readonly fuseConfirmation = inject(FuseConfirmationService);
    readonly isDev = !environment.production;
    readonly isServer = isPlatformServer(this.platformId);
    readonly isBrowser = isPlatformBrowser(this.platformId);
    readonly title = inject(Title);
    readonly meta = inject(Meta);
    // readonly snackBar = inject(MatSnackBar);
    readonly snack = inject(SnackService);

    constructor(@Inject(PLATFORM_ID) private platformId: any) { }

    get history() {
        return this.isServer ? { state: {} } : window.history;
    }

    seo = (e: { title: string, description: string, image: string, url?: string}) => {
        this.title.setTitle(e.title);

        this.meta.updateTag({ name: 'title', content: e.title });
        this.meta.updateTag({ property: 'og:title', content: e.title });
        this.meta.updateTag({ name: 'twitter:title', content: e.title });

        this.meta.updateTag({ name: 'description', content: e.description });
        this.meta.updateTag({ name: 'og:description', content: e.description });
        this.meta.updateTag({ property: 'og:description', content: e.description });
        this.meta.updateTag({ name: 'twitter:description', content: e.description });

        this.meta.updateTag({ property: 'og:image', content: e.image });
        this.meta.updateTag({ name: 'og:image', content: e.image });
        this.meta.updateTag({ name: 'twitter:image', content: e.image });

        this.meta.updateTag({ property: 'og:url', content: e.url ?? environment.url });
        this.meta.updateTag({ name: 'robots', content: 'follow' });
    }


    logInvalidFields = (form: FormGroup) => console.warn(Object.entries(form.controls).filter(([key, e]) => e.invalid).map(([key, e]) => ({ name: key, status: e.valid })));

    handleError<T>(e: HttpErrorResponse, source: Observable<T>) {
        // console.dir(e.error.Message);
        return of({ code: -10, message: `${e.status} : ${e.error?.Message ?? e.message}` }).pipe(
            tap(_ => console.warn(e)),
            take(1),
            // tap(e => this.snack.open(e.message)),
            map(e => e as T & { code: number, message: string }),
            // switchMap(_ => source),
        );
    }

    handleResponseAndError<T>() {
        return (source: Observable<T>): Observable<T & { code: number, message: string }> =>
         source.pipe(
            // tap(e => console.warn('handleError handleResponseAndError')),
            catchError(this.handleError),
            // tap(e => console.warn('map handleResponseAndError')),
            map((e: any) => ({
                ...e,
                code: e?.code < 0 ? -1 : 1,
                message: e?.code < 0 ? e.message : 'Enregistrement réussi'
            }))
        );
    }

    // handleResponseAndError<T>(source: Observable<T>): Observable<T & { code: number, message: string }> {
    //     return source.pipe(
    //         tap(e => console.warn('handleError handleResponseAndError')),
    //         catchError(this.handleError),
    //         tap(e => console.warn('map handleResponseAndError')),
    //         map((e: any) => ({
    //             ...e,
    //             code: e?.code < 0 ? -1 : 1,
    //             message: e?.code < 0 ? e.message : 'Enregistrement réussi'
    //         }))
    //     );
    // }

    jsonParse<T>(e: string) {
        try {
            return JSON.parse(e) as T;
        } catch (error) {
            console.warn(error.messagek);
            return null;
        }
    }
}

export type TypeForm<T> = FormGroup<{
    [Property in keyof T]: T[Property] extends any[] ? FormArray<TypeForm<T[Property][0]>> : FormControl<T[Property]>;
}>;

// export type TypeFormOld<T> = {
//     [Property in keyof T]: FormControl<T[Property]>;
// };

// export type TypeFormA<T> = {
//     [Property in keyof T]: AbstractControl<T[Property]>;
//     // [K in keyof T]: T[K] extends FormControl<infer U> ? U : T[K]
// };



// export type TypeFormA2<T> = FormArray<FormGroup<{
//     [Property in keyof T]: FormControl<T[Property]>;
// }>>
